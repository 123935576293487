.taskContainer {
  border-radius: 15px;
  border: 4px solid #656D78;
  margin-top: 30px;
}

.taskContainerMobile {

}

.taskContainerNotMobile {

}

.taskTopBar {
  border-radius: 10px;
  background-color: #656D78;
  margin-top: -2px;
  margin-left: -2px;
  margin-right: -2px;
  text-align: left;

  padding-top: 15px;
  padding-bottom: 15px;
  display: flex;
  align-items: center;
}

.taskTopBarText input {
  background-color: #F5F7FA70;
  border: 2px solid #00000000;
  font-size: 22px;
  color: #F5F7FA;
}

.taskTopBarText input:focus {
  background-color: #F5F7FAAA;
  outline: none !important;
  border:2px solid #F5F7FA;
}

.occuranceTextBox {
  width: 50px;
}

.taskTopBarText select {
  background-color: #F5F7FA70;
  border: 2px solid #00000000;
  font-size: 22px;
  color: #F5F7FA;
  margin:2px;
  padding:2px;
}

.taskTopBarText {
  margin-left: 15px;
}

.taskTopBarSummary {
  margin-left: auto;
  text-align: center;
  padding-right: 20px;
  padding-left: 15px;
}

.taskTopBarSummary a {
  color: #F5F7FADD;
}

.taskTopBarSummary a:hover {
  color: #F5F7FA;
}

.taskTopBar h1 {
  color: #F5F7FA;
  font-weight: 600;
  font-size: 22px;
}

.taskTopBar h2 {
  color: #F5F7FADD;
  font-size: 14px;
}

.taskTopBarText a {
  color: #F5F7FADD;
}

.taskTopBarText a:hover {
  color: #F5F7FA;
}

.taskTopBarText a {
    padding-left: 5px;
    padding-right: 5px;
}

.taskList {
  display: flex;
  padding:10px;
}

.taskCompleted {
  border: 4px solid #656D78;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  margin:5px;

  display: flex;
  align-items: center;
  justify-content: center;

}

.taskCompletedText {
  text-align: center;
}

.taskCompleted h1 {
  color: #F5F7FA;
  font-weight: 600;
  font-size: 14px;
}

.taskCompleted h2 {
  color: #F5F7FADD;
  font-size: 8px;
}

.taskUncompleted {
  border: 4px solid #656D78;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  margin:5px;

  display: flex;
  align-items: center;
  justify-content: center;
}

.taskUncompleted h1 {
  color: #656D78;
  font-size:70px;
  opacity:0.5;
}

.taskUncompleted h1:hover {
  opacity:1.0;
}
