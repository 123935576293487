.App {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1;

  background-color: #E6E9ED;
}


.circle {
    border-radius: 50%;
    width: 25px;
    height: 25px;
    text-align: center;
    font-weight: 500;
    margin-right: 10px;
    line-height: 25px;

}

.footerContainer {
  position:fixed;
  bottom:0;
  padding: 20px;
}

.footerContainer a {
  text-decoration: none;
  color: #656D78;
}

.footerContainer a:hover {
  text-decoration: none;
  color: #AAB2BD;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1;

  background-color: #F5F7FA;
}
