.attributionsContainer h1 {
  font-size: 30px;
  font-weight: 300;
  margin-top: 30px;
  margin-bottom: 30px;
}

.attributionsContainer p {
  padding:5px;
}

.attributionsContainer a {
  color:#5D9CEC;
  font-weight: 500;
  text-decoration: none;
}

.attributionsContainer a:hover {
  color:#4A89DC;
  font-weight: 700;
  text-decoration: none;
}
