.taskListContainer {
  align-items: center;
  justify-content: center;
  flex-direction: column;

  margin-top: 30px;
}

.taskListContainerMobile {
  width: 90%;
  margin-left: 5%;
  margin-right: 5%;
}

.taskListContainerDesktop {
  width: 500px;
  margin-left: 20px;
}

.taskListTopBar {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.taskListTopBar h1 {
  font-size: 38px;
  color: #1e2142;
  font-weight: 500;
}

.button-primary{
  padding: 15px;
  background: #1e2142;
  color: #F5F7FA;
  text-decoration: none;
  border-radius: 10px;
  font-size: 24px;

  margin-left: auto;
}

.button-primary:hover{
  color: #F5F7FACC;
}
