.NavigationComponent {
  width: 100%;
  background-color: #fff;
  display: flex;
  flex-direction: row;
}

.NavigationComponent h1 {
  font-size: 34px;
  font-weight: 300;
  margin-top: 15px;
  margin-bottom: 15px;
  margin-left: 40px;
  color: #d45c5a;
}

.NavigationComponent h2 {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  padding:5px 15px 5px 15px;
  background-color:#AAB2BD;
  margin-left: auto;
  margin-right:40px;
  margin-top: 15px;
  margin-bottom: 15px;
  color: #ffffff;
}
